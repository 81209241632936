<template>
    <v-card outlined dense>
        <v-card-title>
            <b>DESTINO</b>
            <v-spacer></v-spacer>
            <v-btn @click="printEnvio(7)" dark color="green">A4</v-btn> -
            <v-btn @click="printEnvio(6)" dark color="green">TICKET</v-btn>
        </v-card-title>
        <v-card-text>
            <v-row dense align="center" class="pa-2">
                <v-col cols="12" sm="12">
                    <v-row dense>
                        <v-col cols="12" sm="4">
                            <v-autocomplete
                                dense
                                v-model="isDepartamento"
                                :items="departamentos"
                                item-text="name"
                                placeholder="Departamento"
                                item-value="id"
                                label="Departamento"
                                autocomplete="nope"
                                outlined
                            />
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-autocomplete
                                autocomplete="nope"
                                dense
                                v-model="isProvincia"
                                :items="provincias"
                                item-text="name"
                                placeholder="provincia"
                                item-value="id"
                                outlined
                                label="Provincia"
                            />
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-autocomplete
                                autocomplete="nope"
                                dense
                                v-model="isDistrito"
                                :items="distritos"
                                item-text="name"
                                outlined
                                item-value="id"
                                label="Distrito"
                            />
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field outlined dense v-model="datos.direccion" label="Dirección de envío" placeholder="direccion" />
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field outlined dense v-model="datos.referencia" label="Referencia" />
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field outlined dense v-model="datos.atributos.nombreProducto" label="Nombre del producto" />
                </v-col>
                <v-col cols="12" sm="4">
                    <v-text-field
                        outlined
                        dense
                        v-model="datos.atributos.documentoReceptor"
                        append-icon="mdi-account-search"
                        @click:append="getDatosExternal"
                        label="DNI/RUC (Recibe)"
                    />
                </v-col>
                <v-col cols="12" sm="4">
                    <v-text-field outlined dense v-model="datos.atributos.fullNameReceptor" label="Nombre/Razon Social (Recibe)" />
                </v-col>
                <v-col cols="12" sm="4">
                    <v-text-field outlined dense v-model="datos.celularContacto" label="Celular (Recibe)" />
                </v-col>
                <v-col cols="12" sm="3">
                    <v-autocomplete
                        outlined
                        dense
                        v-model="datos.formaEntrega"
                        item-text="text"
                        item-value="id"
                        :items="tipoFormaEntrega"
                        label="Forma de Entrega"
                    />
                </v-col>
                <template v-if="datos.agenciaEnvio == 13">
                    <v-col cols="12" sm="3">
                        <v-text-field outlined dense v-model="datos.atributos.otherAgenciaEnvio" label="Nombre Agencia" />
                    </v-col>
                    <v-col cols="12" sm="3">
                        <v-text-field outlined dense v-model="datos.atributos.dirAgenciaEnvio" label="Dirección Agencia" />
                    </v-col>
                </template>

                <v-col cols="12" sm="3">
                    <v-text-field outlined dense v-model="datos.googleMaps" label="Enlace de Google Maps" />
                </v-col>
                <!-- <v-col cols="12" sm="12" v-if="datos.celularContacto"> -->
                <v-col cols="12" sm="12" v-if="datos.celularContacto">
                    <v-col>
                        <v-btn
                            :disabled="datos.atributos?.confirmacionDatoEnvio"
                            :dark="!datos.atributos?.confirmacionDatoEnvio"
                            class="green"
                            target="_blank"
                            elevation="2"
                            @click="sendSmsWhatsapp"
                            >CONFIRMACION DE DATOS DE ENVIO POR WSP</v-btn
                        >
                    </v-col>
                    <v-col>
                        <v-btn
                            :disabled="datos.atributos?.mensajeProgramaEnvio"
                            @click="ProgramWhatsapp"
                            :class="{ 'mr-3': true, green: true }"
                            :dark="!datos.atributos?.mensajeProgramaEnvio"
                            >Programado</v-btn
                        >
                        <v-btn
                            :disabled="datos.atributos?.mensajeEnCamino"
                            @click="routeWhatsapp"
                            :class="{ 'mr-3': true, green: true }"
                            :dark="!datos.atributos?.mensajeEnCamino"
                            >En camino</v-btn
                        >
                        <v-btn
                            :disabled="datos.atributos?.mensajeEntregado"
                            @click="deliveredWhatsapp"
                            :class="{ 'mr-3': true, green: true }"
                            :dark="!datos.atributos?.mensajeEntregado"
                            >Entregado</v-btn
                        >
                        <v-btn
                            :disabled="datos.atributos?.mensajeAgencia"
                            @click="agendarWhatsapp"
                            :class="{ 'mr-3': true, green: true }"
                            :dark="!datos.atributos?.mensajeAgencia"
                            >Agencia</v-btn
                        >
                        <v-btn
                            :disabled="datos.atributos?.mensajeAplicativo"
                            @click="aplicativoWhatsapp"
                            :class="{ 'mr-3': true, green: true }"
                            :dark="!datos.atributos?.mensajeAplicativo"
                            >Aplicativo</v-btn
                        >
                        <v-btn
                            :disabled="datos.atributos?.mensajeRecojo"
                            @click="recojoWhatsapp"
                            :class="{ 'mr-3': true, green: true }"
                            :dark="!datos.atributos?.mensajeRecojo"
                            >Recojo</v-btn
                        ></v-col
                    >
                </v-col>
                <v-col cols="12" sm="12" v-else>
                    <h4 style="color: red">No hay un numero registrado</h4>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-title>
            <b>Entrega de producto</b>
        </v-card-title>
        <v-card-title>
            <v-row dense align="center" class="pa-2">
                <v-col cols="12" sm="3">
                    <v-autocomplete
                        outlined
                        dense
                        v-model="datos.tipoEntrega"
                        item-text="text"
                        item-value="id"
                        :items="tipoSelectEntrega"
                        label="Tipo de entrega"
                    />
                </v-col>
                <v-col cols="12" sm="3">
                    <v-text-field dense outlined v-model="datos.montoDelivery" label="Monto" />
                </v-col>
                <v-col cols="12" sm="3">
                    <v-autocomplete
                        outlined
                        dense
                        v-model="datos.atributos.metodoPago"
                        item-text="descripcion"
                        item-value="id"
                        :items="metodoPago"
                        label="Metodo de Pago"
                    />
                </v-col>
                <v-col cols="12" sm="3">
                    <v-menu v-model="menu5" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                dense
                                outlined
                                v-model="datos.atributos.fechaPago"
                                label="Fecha de Pago"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="datos.atributos.fechaPago" no-title locale="es" @input="menu5 = false" />
                    </v-menu>
                </v-col>
                <v-col cols="12" sm="4">
                    <v-text-field outlined dense type="number" v-model="datos.atributos.pesoProducto" label="Peso del Producto en Kg" />
                </v-col>
                <v-col cols="12" sm="3" v-if="datos.atributos.metodoPago != 1">
                    <v-autocomplete
                        outlined
                        dense
                        v-model="datos.formaPago"
                        item-text="description"
                        item-value="id"
                        :items="listTipoBancoDestino"
                        label="Destino Banco"
                    />
                </v-col>
                <v-col cols="12" sm="3">
                    <v-text-field outlined dense v-model="datos.destinoFinal" label="Destino Final" />
                </v-col>
                <v-col cols="12" sm="3">
                    <v-autocomplete
                        outlined
                        dense
                        v-model="datos.estadoDeuda"
                        item-text="text"
                        item-value="value"
                        :items="tipoEstadoDeuda"
                        label="Estado Deuda"
                    />
                </v-col>
                <v-col cols="12" sm="3">
                    <v-text-field outlined dense type="number" v-model="datos.atributos.bultos" label="Bultos" />
                </v-col>
                <v-col cols="12" sm="3">
                    <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                dense
                                outlined
                                v-model="datos.atributos.fechaEnvio"
                                label="Fecha de Envio"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="datos.atributos.fechaEnvio" no-title locale="es" @input="menu1 = false" />
                    </v-menu>
                </v-col>
                <v-col cols="12" sm="3">
                    <v-autocomplete outlined dense v-model="datos.atributos.supervisor" :items="personal" label="Supervisor"></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="3">
                    <v-autocomplete
                        data-vv-name="datos.atributos.idModotransporte"
                        data-vv-as="idModotransporte"
                        outlined
                        dense
                        v-model="datos.atributos.idModotransporte"
                        item-text="description"
                        item-value="id"
                        :items="modoTransporte"
                        label="Modo de Transporte"
                    />
                </v-col>
                <v-col cols="12" sm="3">
                    <v-autocomplete
                        outlined
                        dense
                        v-model="datos.agenciaEnvio"
                        item-text="name"
                        item-value="id"
                        :items="listDispatchers"
                        label="Agencia de envío"
                    />
                </v-col>
                <v-col v-if="datos.atributos.idModotransporte == 2" cols="12" sm="3">
                    <v-autocomplete
                        outlined
                        dense
                        v-model="datos.atributos.idChofer"
                        :items="listDrivers"
                        label="Chofer"
                        item-text="name"
                        item-value="id"
                    ></v-autocomplete>
                </v-col>
                <v-col v-if="datos.atributos.idModotransporte == 2" cols="12" sm="3">
                    <v-autocomplete
                        dense
                        v-model="datos.atributos.placaVehiculo"
                        :items="listTransport"
                        placeholder="Placas"
                        label="Placa de vehiculo"
                        item-text="plate_number"
                        item-value="id"
                        outlined
                    />
                </v-col>
                <v-col cols="12" sm="3">
                    <v-text-field outlined dense v-model="datos.atributos.linkFotoProducto" label="Clave de Envio" />
                </v-col>
                <v-col cols="12" sm="3">
                    <v-text-field outlined dense v-model="datos.atributos.guiaAgencia" label="Guia de Agencia" />
                </v-col>
                <v-col cols="12">
                    <v-col cols="12" sm="3">
                        <v-btn
                            color="secondaryTheme"
                            :disabled="datos.atributos.envioPagoRealizado || !datos.montoDelivery || !datos.atributos.metodoPago || !datos.atributos.fechaPago"
                            @click="sendModule"
                            >Enviar a Modulo Pagos</v-btn
                        >
                    </v-col>
                    <p v-if="datos.atributos.envioPagoRealizado">Ya se envio el pago realizado al modulo de pagos</p>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-row>
                                <v-col cols="12">
                                    <h5>Datos de Fabricación</h5>
                                </v-col>
                                <v-col cols="6">
                                    <v-menu
                                        v-model="menu2"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                dense
                                                outlined
                                                v-model="datos.atributos.fechaFabricacion"
                                                label="Fecha Fabricacion"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-on="on"
                                            />
                                        </template>
                                        <v-date-picker v-model="datos.atributos.fechaFabricacion" no-title locale="es" @input="menu2 = false" />
                                    </v-menu>
                                </v-col>
                                <v-col cols="6">
                                    <v-autocomplete
                                        outlined
                                        dense
                                        v-model="datos.atributos.encargadoFabricacion"
                                        :items="personal"
                                        label="Encargado Fabricación"
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-row>
                                <v-col cols="12">
                                    <h5>Datos de Equipamiento</h5>
                                </v-col>
                                <v-col cols="6">
                                    <v-menu
                                        v-model="menu3"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                dense
                                                outlined
                                                v-model="datos.atributos.fechaEquipamiento"
                                                label="Fecha Equipamiento"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-on="on"
                                            />
                                        </template>
                                        <v-date-picker v-model="datos.atributos.fechaEquipamiento" no-title locale="es" @input="menu3 = false" />
                                    </v-menu>
                                </v-col>
                                <v-col cols="6">
                                    <v-autocomplete
                                        outlined
                                        dense
                                        :items="personal"
                                        v-model="datos.atributos.encargadoEquipamiento"
                                        label="Encargado Equipamiento"
                                    >
                                    </v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12">
                    <h5>Datos de Embalaje</h5>
                </v-col>
                <v-col cols="12" sm="3">
                    <v-menu v-model="menu4" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                dense
                                outlined
                                v-model="datos.atributos.fechaEmbalaje"
                                label="Fecha Embalaje"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="datos.atributos.fechaEmbalaje" no-title locale="es" @input="menu4 = false" />
                    </v-menu>
                </v-col>
                <v-col cols="12" sm="3">
                    <v-autocomplete outlined dense :items="personal" v-model="datos.atributos.encargadoEmbalaje" label="Encargado Embalaje"></v-autocomplete>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="8">
                    <v-alert
                        v-if="disabledGuideRemission && camposFaltantes.length"
                        type="warning"
                        dense
                        outlined
                        border="left"
                        color="warning"
                        class="mb-4"
                        icon="mdi-alert-circle-outline"
                    >
                        <div class="font-weight-medium mb-2">⚠️ Faltan completar los siguientes campos:</div>
                        <ul class="pl-4">
                            <li v-for="(campo, index) in camposFaltantes" :key="index">
                                <v-icon small color="warning" class="mr-1">mdi-alert</v-icon>
                                {{ campo }}
                            </li>
                        </ul>
                    </v-alert>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" sm="3">
                    <v-btn :disabled="disabledGuideRemission" color="primary" :loading="loadGuide" @click="printRemission"> IMPRIMIR GUIA REMISION </v-btn>
                    <h5 v-if="datos.numeroGuiaRemision">SE REALIZO LA EMISION</h5>
                </v-col>
            </v-row>
        </v-card-title>
    </v-card>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { mixins } from '@/mixins/mixin.js'
import { format } from 'date-fns'
import isThisMinute from 'date-fns/isThisMinute/index.js'
import { sleep } from '@/utils/genericUtils'
import DefaultForm from '@/utils/defaultForm'

export default {
    filters: {},
    mixins: [mixins],
    $_veeValidate: {
        validator: 'new'
    },
    components: {},
    data() {
        return {
            loadGuide: false,
            options: [
                { text: 'PAGADO', value: 2 },
                { text: 'PENDIENTE', value: 1 }
            ],
            metodoPago: [
                { id: 1, descripcion: 'Efectivo' },
                { id: 2, descripcion: 'Tarjeta' },
                { id: 3, descripcion: 'Transferencia' }
            ],
            selected: 1,
            menu1: false,
            menu2: false,
            menu3: false,
            menu4: false,
            menu5: false,
            isDepartamento: null,
            isProvincia: null,
            isDistrito: null
        }
    },
    computed: {
        ...mapState('utilities', ['tipoFormaEntrega', 'tipoSelectEntrega', 'tipoEstadoDeuda', 'tipoBancoDestino', 'modoTransporte']),
        ...mapState('ventas', ['datos']),
        ...mapState('proveedor', ['listDispatchers', 'listDrivers', 'listTransport']),
        ...mapState('users', ['personal']),
        ...mapState('sedes', ['departamentos', 'provincias', 'distritos']),
        ...mapGetters('utilities', ['listTipoBancoDestino']),
        ...mapGetters('users', ['listaChofer']),
        disabledGuideRemission() {
            return (
                !!this.datos.numeroGuiaRemision ||
                !this.datos.productos ||
                !this.datos.atributos.fechaEnvio ||
                !this.datos.idCliente ||
                !this.datos.referencia ||
                !this.datos.atributos.idModotransporte ||
                !this.datos.atributos.pesoProducto ||
                !this.datos.atributos.bultos ||
                !this.datos.atributos.fullNameReceptor ||
                !this.datos.atributos.documentoReceptor ||
                !this.datos.celularContacto ||
                !this.datos.numeroComprobante
            )
        },
        camposFaltantes() {
            const faltantes = []

            if (this.datos.numeroGuiaRemision) return []

            if (!this.datos.productos) faltantes.push('Productos')
            if (!this.datos.atributos.fechaEnvio) faltantes.push('Fecha de Envío')
            if (!this.datos.idCliente) faltantes.push('Cliente')
            if (!this.datos.referencia) faltantes.push('Referencia')
            if (!this.datos.atributos.idModotransporte) faltantes.push('Modo de Transporte')
            if (!this.datos.atributos.pesoProducto) faltantes.push('Peso del Producto')
            if (!this.datos.atributos.bultos) faltantes.push('Número de Bultos')
            if (!this.datos.atributos.fullNameReceptor) faltantes.push('Nombre del Receptor')
            if (!this.datos.atributos.documentoReceptor) faltantes.push('Documento del Receptor')
            if (!this.datos.celularContacto) faltantes.push('Celular de Contacto')
            if (!this.datos.numeroComprobante) faltantes.push('Número de Comprobante')
            return faltantes
        }
    },
    methods: {
        async sendModule() {
            await this.$store.dispatch('pagos/enviar_moduloPago', this.datos)
            await this.$store.dispatch('ventas/patchVenta', { ...this.datos, atributos: { ...this.datos.atributos, envioPagoRealizado: true } })
        },
        async printRemission() {
            this.loadGuide = true
            console.log('aqui')
            const receptor = `Se realiza la entrega al USUARIO: ${this.datos.atributos.fullNameReceptor}
          con NUMERO DOCUMENTO: ${this.datos.atributos.documentoReceptor} y TELEFONO: ${this.datos.celularContacto}\n`
            const idLocation = [this.isDepartamento, this.isProvincia, this.isDistrito]
            const { numeroGuiaRemision: idGuuiaRemision, urlNumeroGuiaRemision: urlGuiaRemision } = await this.$store.dispatch('reporte/sendGuiaRemision', {
                ...this.datos,
                idLocation,
                receptor
            })
            this.datos.numeroGuiaRemision = idGuuiaRemision
            console.log('logs---', {
                numeroGuiaRemision: idGuuiaRemision,
                atributos: {
                    ...this.datos.atributos,
                    urlGuiaRemision
                }
            })
            await this.$store.dispatch('ventas/patchVenta', {
                ...this.datos,
                activo: 1,
                numeroGuiaRemision: idGuuiaRemision,
                atributos: {
                    ...this.datos.atributos,
                    urlGuiaRemision
                }
            })

            this.$store.commit('ventas/SHOW_MODAL', { step: 1, state: false })
            // LIMPIAR CACHE DE DATOS

            this.$store.commit('ventas/RESET_DATOS', DefaultForm.formDefault())
            this.loadGuide = false
        },
        async getDatosExternal() {
            if (this.datos.atributos.documentoReceptor >= 8) {
                try {
                    console.log('this.datos.atributos.documentoReceptor', this.datos.atributos.documentoReceptor.length)
                    const idTipoDocumento = this.datos.atributos.documentoReceptor.length == 8 ? 1 : 6
                    this.$store.commit('mainUI/OPEN_MODAL', {
                        state: true,
                        text: 'Buscando en Reniec/Sunat',
                        color: 'green'
                    })
                    console.log('this.datps', idTipoDocumento)
                    const resultado = await this.$store.dispatch('clientes/getClienteExternal', {
                        idTipoDocumento: idTipoDocumento,
                        numeroDocumento: this.datos.atributos.documentoReceptor
                    })
                    // //console.log('myresultado', resultado)

                    const atributos = {
                        ...this.datos.atributos,
                        fullNameReceptor: resultado['nombre_completo'] ? resultado['nombre_completo'] : resultado['nombre_o_razon_social']
                    }
                    console.log('---- ', atributos)

                    this.$store.commit('ventas/UPDATE_DATOS', {
                        atributos: atributos
                    })
                    console.log('here---1')
                    this.$store.commit('mainUI/OPEN_MODAL', { state: false })
                } catch (error) {
                    console.log('error ', error)
                    this.$store.commit('mainUI/OPEN_MODAL', { state: false })

                    //console.log('error ', error)
                }
            }
        },
        async ProgramWhatsapp() {
            console.log('this.datos', this.datos)
            const { full_name, celularContacto, telefono } = this.datos
            const requiredParams = {
                nombreCliente: full_name,
                telefono: `51${celularContacto || telefono}`
            }
            const params = await this.$store.dispatch('utilities/botWhatsappVerify', requiredParams)
            if (params.status) {
                await this.$store.dispatch('ventas/botWhatsapp', { idTemplate: 4, ...requiredParams })
                await this.$store.dispatch('ventas/patchVenta', { ...this.datos, atributos: { ...this.datos.atributos, mensajeProgramaEnvio: true } })
            }
            const text = params.status ? 'Mensaje enviado correctamente' : `ERROR  -  ${params.empty} vacio`
            this.$store.commit('mainUI/OPEN_MODAL', {
                state: true,
                text,
                color: 'green'
            })
            await sleep(3000)
            this.$store.commit('mainUI/OPEN_MODAL', { state: false })
        },

        async agendarWhatsapp() {
            console.log('here agendar Whatsapp')
            console.log('this.datos', this.datos)
            const { full_name, celularContacto, telefono, direccion, id, atributos, referencia, productos } = this.datos
            const prodPriceDes = productos.sort((a, b) => b.price - a.price)
            const nombreDep = this.isDepartamento ? this.departamentos.find((x) => x.id == this.isDepartamento).name : ''
            const nombreProv = this.isProvincia ? this.provincias.find((x) => x.id == this.isProvincia).name : ''
            const nombreDist = this.isDistrito ? this.distritos.find((x) => x.id == this.isDistrito).name : ''
            const formaEntrega = this.tipoFormaEntrega.find((x) => x.id == this.datos.formaEntrega)
            const agenciaEnvio = this.listDispatchers.find((x) => x.id == this.datos.agenciaEnvio)
            let nameProductos = '--'
            const principalProducto = `${prodPriceDes[0].description}, ${prodPriceDes[0].unidades} unidad(s)`
            const seconProds = prodPriceDes.slice(1)
            for (const iterator of seconProds) {
                nameProductos =
                    prodPriceDes.length <= 1 ? '--' : nameProductos.concat(`• 📦 ${iterator?.description || ''} ,${iterator?.unidades || ''} unidad(s)`)
            }
            const destino = `${direccion} - ${nombreDep} - ${nombreProv} - ${nombreDist} - Referencia - ${referencia}`
            const requiredParams = {
                telefono: `51${celularContacto || telefono}`,
                nombreCliente: full_name,
                idVenta: id,
                principalProducto,
                listaProductos: nameProductos,
                destino,
                fullNameReceptor: atributos?.fullNameReceptor,
                documentoReceptor: atributos?.documentoReceptor,
                celularContacto,
                formaEntrega: formaEntrega?.text,
                agenciaEnvio: agenciaEnvio?.name
            }
            const params = await this.$store.dispatch('utilities/botWhatsappVerify', requiredParams)
            if (params.status) {
                await this.$store.dispatch('ventas/botWhatsapp', {
                    idTemplate: 7,
                    ...requiredParams
                })
                await this.$store.dispatch('ventas/patchVenta', { ...this.datos, atributos: { ...this.datos.atributos, mensajeAgencia: true } })
            }
            const text = params.status ? 'Mensaje enviado correctamente' : `ERROR  -  ${params.empty} vacio`
            this.$store.commit('mainUI/OPEN_MODAL', {
                state: true,
                text,
                color: 'green'
            })
            await sleep(3000)
            this.$store.commit('mainUI/OPEN_MODAL', { state: false })
        },

        async aplicativoWhatsapp() {
            console.log('aplicativo Whatsapp')
            console.log('this.datos', this.datos)
            const { full_name, celularContacto, telefono, id, montoDelivery, productos } = this.datos
            const namePrinc = productos.length > 0 ? productos.find((x) => x.description) : null
            let listaProductos = ''
            const cant = productos.length - 1
            listaProductos = listaProductos.concat(`• 📦 ${namePrinc.description}  ${cant > 0 ? ` y ${cant} producto(s) más` : ''}`)
            const requiredParams = {
                telefono: `51${celularContacto || telefono}`,
                nombreCliente: full_name,
                idVenta: id,
                montoDelivery: montoDelivery || '---',
                listaProductos
            }
            const params = await this.$store.dispatch('utilities/botWhatsappVerify', requiredParams)
            if (params.status) {
                await this.$store.dispatch('ventas/botWhatsapp', {
                    idTemplate: 8,
                    ...requiredParams
                })
                await this.$store.dispatch('ventas/patchVenta', { ...this.datos, atributos: { ...this.datos.atributos, mensajeAplicativo: true } })
            }
            const text = params.status ? 'Mensaje enviado correctamente' : `ERROR  -  ${params.empty} vacio`
            this.$store.commit('mainUI/OPEN_MODAL', {
                state: true,
                text,
                color: 'green'
            })
            await sleep(3000)
            this.$store.commit('mainUI/OPEN_MODAL', { state: false })
        },

        async recojoWhatsapp() {
            console.log('this.datos', this.datos)
            const { full_name, celularContacto, telefono, id, productos } = this.datos
            const googleMaps = `https://goo.gl/maps/hwtqzLLrwZ8VxeXv7 `
            const namePrinc = productos?.find((x) => x.description) || ''
            let listaProductos = ''
            const cant = productos.length - 1
            listaProductos = listaProductos.concat(`• 📦 ${namePrinc.description}  ${cant > 0 ? ` y ${cant} producto(s) más` : ''}`)
            const requiredParams = {
                telefono: `51${celularContacto || telefono}`,
                nombreCliente: full_name,
                idVenta: id,
                listaProductos,
                googleMaps
            }
            const params = await this.$store.dispatch('utilities/botWhatsappVerify', requiredParams)
            if (params.status) {
                await this.$store.dispatch('ventas/botWhatsapp', {
                    idTemplate: 9,
                    ...requiredParams
                })
                await this.$store.dispatch('ventas/patchVenta', { ...this.datos, atributos: { ...this.datos.atributos, mensajeRecojo: true } })
            }
            const text = params.status ? 'Mensaje enviado correctamente' : `ERROR  -  ${params.empty} vacio`
            this.$store.commit('mainUI/OPEN_MODAL', {
                state: true,
                text,
                color: 'green'
            })
            await sleep(3000)
            this.$store.commit('mainUI/OPEN_MODAL', { state: false })
        },

        async routeWhatsapp() {
            console.log('this.datos', this.datos)
            const { full_name, id, productos, celularContacto, telefono } = this.datos
            let nameProduct
            let index
            productos.map((x, i) => {
                if (i == 0) {
                    nameProduct = x.description
                }
                index = i
            })
            const listaProductos = `${index == 0 ? `📦 ${nameProduct}` : `📦 ${nameProduct} más ${index} producto(s) más `}`
            const requiredParams = {
                telefono: `51${celularContacto || telefono}`,
                nombreCliente: full_name,
                idVenta: id,
                listaProductos
            }
            const params = await this.$store.dispatch('utilities/botWhatsappVerify', requiredParams)
            if (params.status) {
                await this.$store.dispatch('ventas/botWhatsapp', {
                    idTemplate: 5,
                    ...requiredParams
                })
                await this.$store.dispatch('ventas/patchVenta', { ...this.datos, atributos: { ...this.datos.atributos, mensajeEnCamino: true } })
            }
            const text = params.status ? 'Mensaje enviado correctamente' : `ERROR  -  ${params.empty} vacio`
            this.$store.commit('mainUI/OPEN_MODAL', {
                state: true,
                text,
                color: 'green'
            })
            await sleep(3000)
            this.$store.commit('mainUI/OPEN_MODAL', { state: false })
        },
        async deliveredWhatsapp() {
            const { full_name, id, productos, celularContacto, telefono } = this.datos
            let nameProduct
            let index
            productos.map((x, i) => {
                if (i == 0) {
                    nameProduct = x.description
                }
                index = i
            })
            const listaProductos = `${index == 0 ? `📦 ${nameProduct}` : `📦 ${nameProduct} más ${index} producto(s) más `}`
            const requiredParams = {
                telefono: `51${celularContacto || telefono}`,
                nombreCliente: full_name,
                idVenta: id,
                listaProductos
            }
            const params = await this.$store.dispatch('utilities/botWhatsappVerify', requiredParams)
            if (params.status) {
                await this.$store.dispatch('ventas/botWhatsapp', {
                    idTemplate: 6,
                    ...requiredParams
                })
                await this.$store.dispatch('ventas/patchVenta', { ...this.datos, atributos: { ...this.datos.atributos, mensajeEntregado: true } })
            }
            const text = params.status ? 'Mensaje enviado correctamente' : `ERROR  -  ${params.empty} vacio`
            this.$store.commit('mainUI/OPEN_MODAL', {
                state: true,
                text,
                color: 'green'
            })
            await sleep(3000)
            this.$store.commit('mainUI/OPEN_MODAL', { state: false })
        },
        async sendSmsWhatsapp() {
            const {
                direccion,
                referencia,
                atributos,
                celularContacto,
                googleMaps,
                telefono,
                tipoEntrega,
                montoDelivery,
                estadoDeuda,
                agenciaEnvio
            } = this.datos
            const dep = this.isDepartamento ? this.departamentos.find((x) => x.id == this.isDepartamento)?.name : ''
            const prov = this.isProvincia ? this.provincias.find((x) => x.id == this.isProvincia)?.name : ''
            const dist = this.isDistrito ? this.distritos.find((x) => x.id == this.isDistrito)?.name : ''
            const findTipoEntrega = this.tipoSelectEntrega.find((x) => x.id == tipoEntrega)
            const findEstadoDeuda = this.tipoEstadoDeuda.find((x) => x.value == estadoDeuda)
            const findAgenciaEnvio = this.listDispatchers.find((x) => x.id == agenciaEnvio)
            const envioAgencia = `Agencia de envío: ${findAgenciaEnvio?.name || ''}`
            const destino = `${direccion || ''} - ${dep || ''} - ${prov || ''} - ${dist || ''} - referencia - ${referencia || ''}`
            const authorizationPerson = `${atributos?.fullNameReceptor || ''}; Dni:${atributos?.documentoReceptor || ''}; cel.${
                celularContacto || telefono || ''
            }`
            const requiredParams = {
                telefono: `51${celularContacto || telefono}`,
                destino,
                envioAgencia,
                authorizationPerson,
                googleMaps: googleMaps || '--',
                tipoEntrega: findTipoEntrega?.text,
                montoDelivery: montoDelivery || '---',
                estadoDeuda: findEstadoDeuda?.text,
                bultos: atributos?.bultos
            }
            const params = await this.$store.dispatch('utilities/botWhatsappVerify', requiredParams)
            if (params.status) {
                await this.$store.dispatch('ventas/botWhatsapp', {
                    idTemplate: 3,
                    ...requiredParams
                })
                await this.$store.dispatch('ventas/patchVenta', {
                    ...this.datos,
                    atributos: { ...this.datos.atributos, confirmacionDatoEnvio: true }
                })
            }
            const text = params.status ? 'Mensaje enviado correctamente' : `ERROR  -  ${params.empty} vacio`
            this.$store.commit('mainUI/OPEN_MODAL', {
                state: true,
                text,
                color: 'green'
            })
            await sleep(3000)
            this.$store.commit('mainUI/OPEN_MODAL', { state: false })
        },
        async printEnvio(tipoDoc) {
            try {
                const departamento = this.departamentos.find((x) => x.id === this.isDepartamento)
                const provincia = this.provincias.find((x) => x.id === this.isProvincia)
                const distrito = this.distritos.find((x) => x.id === this.isDistrito)

                this.$store.commit('mainUI/OPEN_BAR', {
                    state: true,
                    text: 'generando Reporte'
                })
                const date = format(new Date(), 'dd/MM/yyyy')
                const base64 = await this.$store.dispatch('reporte/reporteDatosEnvio', {
                    ...this.datos,
                    departamento: departamento ? departamento.name : '',
                    provincia: provincia ? provincia.name : '',
                    distrito: distrito ? distrito.name : '',
                    date,
                    tipoDoc
                })

                //this.pathURL = base64
                //console.log('pathURL ', this.pathURL)
            } catch (error) {
                //console.log('err ', error)
                this.$store.commit('mainUI/OPEN_BAR', {
                    state: true,
                    text: 'datos incompletos'
                })
            }
        }
    },
    async mounted() {
        await this.$store.dispatch('proveedor/findProveedor')
        await this.$store.dispatch('proveedor/findDriver')
        await this.$store.dispatch('proveedor/findTransport')
    },
    watch: {
        async isDepartamento(val) {
            //console.log('isDepartamento ', val)
            if (val) {
                await this.$store.dispatch('sedes/getProvincia', val)
            }
        },
        async isProvincia(val) {
            //console.log('provincia ', val)
            if (val) {
                await this.$store.dispatch('sedes/getDistrito', val)
            }
        },
        async isDistrito(val) {
            //console.log('distrito ', val)
            if (val) {
                this.$store.commit('ventas/UPDATE_DATOS', {
                    ubigeo: val
                })
            }
        }
    },
    async created() {
        await Promise.all([
            this.$store.dispatch('sedes/getDepartamento', { online: true }),
            this.$store.dispatch('users/getPersonas'),
            this.$store.dispatch('users/getLista')
        ])
        this.datos.atributos.idModotransporte = this.datos.atributos.idModotransporte || 2
        console.log('this.datos', this.datos)

        if (this.datos.ubigeo) {
            this.isDepartamento = this.datos.ubigeo.slice(0, 2)
            this.isProvincia = this.datos.ubigeo.slice(0, 4)
            this.isDistrito = this.datos.ubigeo
        }
    }
}
</script>
